import axios from "axios";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import AccessRight from "./AccessRight";
import PrintContent from "./PrintContent";
import ReactToPrint from "react-to-print";

const CLEAR_KEYS = ["Escape", "Backspace", "Delete"];
const ALLOWED_KEYS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz".split("");

export interface User {
  id: number;
  fullName: string;
  [key: string]: any;
}

const BarcodeScanner = () => {
  const [input, setInput] = useState("");
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [hasPrinted, setHasPrinted] = useState(false);

  const printContentRef = useRef<HTMLDivElement>();

  const hasUserData = user && Object.keys(user);

  const submitCode = (code) => {
    setInput("");
    setUser(null);
    setLoading(true);
    const [_, label] = code.split("MOA25");
    axios
      .get(`/api/users/identity_label?label=${label}`)
      .then(({ data }) => {
        setUser(data);
        setScanned(false);
        setScanning(false);
        setHasPrinted(data.has_printed);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePrint = () => {
    setScanning(true);
    axios
      .get(`/api/users/print_badge?id=${user.id}`)
      .then(() => {
        setScanned(true);
        setHasPrinted(true);
      })
      .catch((err) => {
        alert(err);
        setHasPrinted(false);
      })
      .finally(() => {
        setScanning(false);
      });
  };

  const downHandler = (e) => {
    if (!editMode) {
      setInput((prevState) => {
        if (e.key === "Enter") {
          submitCode(prevState);
          return prevState;
        } else if (e.key === "Backspace") {
          return prevState.slice(0, -1);
        } else if (CLEAR_KEYS.includes(e.key)) {
          return "";
        } else if (ALLOWED_KEYS.includes(e.key)) {
          return prevState + e.key.toUpperCase();
        } else {
          return prevState;
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [editMode]);

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("id");
    if (id) {
      setInput(id);
      submitCode(id);
    }
  }, []);

  return (
    <div className="row">
      <div className="col-md-8 d-flex flex-column align-items-center justify-content-center">
        <h2 className="text-center text-lightorange">Welcome to</h2>
        <h2 className="text-center text-darkblue">
          54th MOA Annual Scientific Meeting &
        </h2>
        <h2 className="text-center text-darkblue">
          Annual General Meeting 2025!
        </h2>
        <br />
        <h3 className="fw-light">Please Scan Your QR to get started</h3>
        <br />
        <div
          className="p-4 text-center"
          style={{ background: "#cbe0e2", minWidth: 350, minHeight: 80 }}
        >
          <h1 className="m-0">{input}</h1>
        </div>
      </div>

      <div className="col-md-4 d-flex justify-content-center border p-4">
        <style>
          {`
            .border {
              border: 1px solid #000 !important;
              min-height: 50vh;
            }
          `}
        </style>
        {loading ? (
          <p className="my-auto">Loading...</p>
        ) : (
          <>
            {hasUserData ? (
              <div className="d-flex flex-column justify-content-center w-100 h-100">
                {user.eventAccess ? (
                  <>
                    <div className="w-100">
                      <PrintContent
                        ref={printContentRef}
                        user={user}
                        editMode={editMode}
                        setEditMode={setEditMode}
                      />
                    </div>
                    {/* <div className="text-center text-muted mt-1 mb-3 fst-italic">
                        Click on the name to edit.
                      </div> */}
                    {/* <AccessRight user={user} /> */}

                    <div className="text-center mt-3">
                      {/* <ReactToPrint
                        onAfterPrint={() => {
                          handlePrint()
                          setUser(null)
                          setInput('')
                          // Set focus back to the window for typing after printing
                          window.focus()
                        }}
                        trigger={() => <button className="btn btn-xl btn-primary">Click Here to Print</button>}
                        content={() => printContentRef.current}
                      /> */}
                      {!hasPrinted ? (
                        <ReactToPrint
                          onAfterPrint={() => {
                            handlePrint();
                            setUser(null);
                            setInput("");
                            // Set focus back to the window for typing after printing
                            window.focus();
                          }}
                          trigger={() => (
                            <button className="btn btn-xl btn-primary">
                              Click Here to Print
                            </button>
                          )}
                          content={() => printContentRef.current}
                        />
                      ) : (
                        <button disabled className="btn btn-xl btn-danger">
                          Has Printed
                        </button>
                      )}
                    </div>

                    {/* <div className="text-center mt-3">
                      <ReactToPrint
                        onAfterPrint={handlePrint}
                        trigger={() => (
                          <button className="theme-btn px-5 py-3 fs-5">
                            Print
                          </button>
                        )}
                        content={() => printContentRef.current}
                      />
                    </div> */}
                  </>
                ) : (
                  <>
                    <div className="my-2 p-4 w-100">
                      <div className="mb-1">
                        <span className="fw-bold">Full Name:&nbsp;</span>
                        <p>{user.fullNameTitleize}</p>
                      </div>
                      <div className="">
                        <span className="fw-bold">Role:&nbsp;</span>
                        <p>{user.roleTitleize}</p>
                      </div>
                    </div>
                    <div className="text-center text-danger fw-bold">
                      User doesn't have the conference ticket.
                    </div>
                  </>
                )}
              </div>
            ) : (
              <p className="my-auto fw-bold fs-4">No Data Found</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BarcodeScanner;
