// app/javascript/components/MultiPicker.js
import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

const MultiPicker = ({ name, currentUserId }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const wordCount = inputValue.trim().length;

    if (wordCount >= 3) {
      axios
        .get(`/api/users/quiz_search`, {
          params: { query: inputValue, currentUserId: currentUserId },
        })
        .then((response) => {
          const fetchedOptions = response.data.users.map((u) => ({
            label: `${u.full_name} - ${u.email}`,
            value: u.id,
          }));
          setSelectOptions(fetchedOptions);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [inputValue, currentUserId]);

  return (
    <Select
      name={`quiz_team[${name}]`}
      options={selectOptions}
      value={selectedOption}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder="Search member by name"
      isClearable
      isSearchable
    />
  );
};

export default MultiPicker;
