import axios from "axios";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import AccessRight from "./AccessRight";
import PrintContent from "./PrintContent";
import ReactToPrint from "react-to-print";

const CLEAR_KEYS = ["Escape", "Backspace", "Delete"];
const ALLOWED_KEYS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz".split("");

export interface User {
  id: number;
  fullName: string;
  [key: string]: any;
}

const BarcodeScanner = () => {
  const [input, setInput] = useState("");
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [scanning, setScanning] = useState(false);

  const printContentRef = useRef<HTMLDivElement>();

  const hasUserData = user && Object.keys(user);

  const submitCode = (code) => {
    setInput("");
    setUser(null);
    setLoading(true);
    const [_, label] = code.split("MOA25");
    axios
      .get(`/api/users/identity_label?label=${label}`)
      .then(({ data }) => {
        setUser(data);
        setScanned(false);
        setScanning(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePrint = () => {
    setScanning(true);
    axios
      .get(`/api/users/print_badge?id=${user.id}`)
      .then(() => {
        setScanned(true);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setScanning(false);
      });
  };

  const downHandler = (e) => {
    if (!editMode) {
      setInput((prevState) => {
        if (e.key === "Enter") {
          submitCode(prevState);
          return prevState;
        } else if (e.key === "Backspace") {
          return prevState.slice(0, -1);
        } else if (CLEAR_KEYS.includes(e.key)) {
          return "";
        } else if (ALLOWED_KEYS.includes(e.key)) {
          return prevState + e.key.toUpperCase();
        } else {
          return prevState;
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [editMode]);

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("id");
    if (id) {
      setInput(id);
      submitCode(id);
    }
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="text-muted mb-2 fw-bold text-center">
        <span className="text-success">Start Scanning</span>
        <div className="fst-italic">or</div>
        <span className="text-danger">
          Start typing in QR Label and Press Enter
        </span>
      </div>
      <div className="text-muted mb-3">(Make sure this window is in focus)</div>
      <div
        className="p-4 text-center"
        style={{ background: "#cbe0e2", minWidth: 350, minHeight: 80 }}
      >
        <h1 className="m-0">{input}</h1>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {hasUserData ? (
            <div className="row" style={{ minWidth: 750 }}>
              <div className="col-md-8">
                <>
                  <div className="mt-3">Print Content:</div>
                  <div className="card mt-2 p-4 w-100">
                    <PrintContent
                      ref={printContentRef}
                      user={user}
                      editMode={editMode}
                      setEditMode={setEditMode}
                    />
                  </div>
                  <div className="text-center text-muted mt-1 mb-3 fst-italic">
                    To edit, click on any of the text above.
                  </div>
                  <div className="text-center">
                    <ReactToPrint
                      onAfterPrint={handlePrint}
                      trigger={() => (
                        <button className="btn btn-sm btn-danger">
                          Click Here to Print
                        </button>
                      )}
                      content={() => printContentRef.current}
                    />
                  </div>
                </>
                <a
                  className="mt-3 text-center d-block"
                  href={`/admin/users/${user.id}`}
                  target="_blank"
                >
                  <span>View User Profile</span>
                  <i className="fas fa-arrow-right ms-2"></i>
                </a>
              </div>
              <div className="col-md-4">
                <AccessRight user={user} />
              </div>
            </div>
          ) : (
            <p>No Data Found</p>
          )}
        </>
      )}
    </div>
  );
};

export default BarcodeScanner;
